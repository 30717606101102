import React from 'react'
import cls from './swiperItem.module.scss';
interface Item {
    title: string;
    description: string;
    image: string;
    avatar: string;
    name: string;
    status: string;
    buttonText: string;
  }
  
  interface CustomSliderProps {
    item: Item;
  }

const SwiperItem : React.FC<CustomSliderProps> = ({ item }) => {
    
    return (
        <div className={cls.slide_item_content}>
              <picture className={cls.slide_img}>
                <img src={`./assets/slides/${item.image}`} alt='' />
              </picture>
              <div className={cls.slide_body}>
                <div className={cls.slide_header}>
                  <div className={cls.creater}>
                    <picture>
                      <img src={`./assets/slides/${item.avatar}`} alt=''/>
                    </picture>
                    
                    <span>{item.name}</span>
                    <picture className={cls.verify}>
                      <img src={`./assets/slides/verify.svg`} alt=''/>
                    </picture>
                  </div>
                  <span className={cls.status}>{item.status}</span>
                </div>
                <div className={cls.body_container}>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                  
                  <a href='https://t.me/tonquester_bot' className={cls.button}>{item.buttonText}</a>
                </div>
                
              </div>
            </div>
    )
}

export default SwiperItem