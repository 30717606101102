import React from 'react';
import cls from './UnderWater.module.scss';

const UnderWater: React.FC = () => {
    return (
        
        <section className={cls.section_background}>
            <h2 className={cls.water_header}>How does it work?</h2>
            <div style={{ backgroundImage: 'url(/assets/backgrounds/water.png)' }} className={cls.background}>
                <ul className={cls.water_cards}>
                    <li className={cls.water_card}>
                        <span>1</span>
                        <p>You: Complete simple tasks that onboard you to the TON ecosystem</p>
                    </li>
                    <li className={cls.water_card}>
                        <span>2</span>
                        <p>Products: Pay you for completing these tasks</p>
                    </li>
                    <li className={cls.water_card}>
                        <span>3</span>
                        <p>You: Withdraw profits and keep using services you like</p>
                    </li>
                    <li className={cls.water_card}>
                        <span>4</span>
                        <p>Products: Happy with obtaining a new customer</p>
                    </li>
                </ul>
            </div>
            <picture>
                <img className={cls.line} src='/assets/images/line.png' alt='' />
            </picture>
            <div className={cls.fishs}>
                <img src='./assets/images/bluefish.png' alt=''/>
                <img src='./assets/images/greenfish.png' alt=''/>
                <img src='./assets/images/yellowfish.png' alt=''/>
                <img src='./assets/images/greenhourse.png' alt=''/>
            </div>
        </section>
    );
};

export default UnderWater;
