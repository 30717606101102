import React from 'react';
import MainScreen from './components/mainScreen/mainScreen';
import UnderWater from './components/underWater/UnderWater';
import SliderBlock from './components/sliderBlock/SliderBlock';
import StoneBlock from './components/stoneBlock.tsx/StoneBlock';
import Footer from './components/footer/footer';
import cls from './index.module.scss'
function App() {

  const items = [
    { image:'1.jpg', avatar:'av.jpg', name:'Tonquester', status:'Finished', title: 'Tonquester', description: 'Desc Take part and receive you part of 100 USDT raffle', buttonText:'Take part' },
    { image:'3.png', avatar:'av1.png', name:'PrimeXBT', status:'Finished', title: 'PrimeXBT', description: 'PrimeXBT will airdrop >1000000$ to their clients on June! Complete quest to get from 5$ to 50$ guaranteed! Bonus will be credited on June.', buttonText:'Take part' },
  
  ];

  return (
    <div className={cls.main_container}>
      <MainScreen/>
      <UnderWater/>
      <SliderBlock items={items}/>
      <StoneBlock/>
      <Footer/>
    </div>
  );
}

export default App;
