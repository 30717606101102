import React from 'react';
import cls from './Button.module.scss';

interface Text {
    content: string;
    link: string;
  }
  
const Button : React.FC<Text> = ({content, link}) => {
    return (
        <a href = {link} target="_blank" className={cls.start_questing_link}>{content}</a>
    );
}

export default Button;
