import React from 'react';
import cls from './mainScreen.module.scss';
import Flower from '../flower/Flower'
import Button from '../button/Button';

const MainScreen: React.FC = () => {
    return (
        <section className={cls.main__screen}>
            <div className={cls.container}>
                <div className={cls.main__screen_content}>
                    <div className={cls.main__screen_body}>
                        <img src="/assets/images/gnom.png" alt="Gnom" />
                        <span className={cls.main__screen_header}>tonquester</span>
                        <p className={cls.main__screen_text}>
                            Complete quests on TON and earn TONs, USDT, NFTs, and whitelists!
                        </p>
                        <Button content={'Start questing'} link='https://t.me/tonquester_bot'/>
                    </div>
                    <div className={cls.background_forest}>
                        <img className={cls.back_grass} src="/assets/images/grass.png" alt="Grass" />
                        <img className={cls.sun} src="/assets/images/Sun.png" alt="Sun" />
                        <div className={cls.flowe}>
                            <Flower/>
                        </div>
                        <img className={cls.forest} src="/assets/images/bg_elements.png" alt="Forest" />
                        <img className={cls.clouds} src="/assets/images/Clouds_bg.png" alt="Clouds Background" />
                        <img className={cls.amin_clouds} src="/assets/images/clouds.png" alt="Animated Clouds" />
                        <img className={cls.mountain} src="/assets/images/mountains.png" alt="Mountains" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MainScreen;
