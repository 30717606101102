import React, { useEffect, useState } from 'react';
import cls from './Flower.module.scss';

const Flower = () => {
  const [frameIndex, setFrameIndex] = useState(0);
  const frames = [
    './assets/images/flow (1).png',
    './assets/images/flow (2).png',
    './assets/images/flow (3).png',
    './assets/images/flow (4).png',
    './assets/images/flow (5).png',
    './assets/images/flow (6).png',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFrameIndex(prevIndex => (prevIndex + 1) % frames.length);
    }, 500);
    return () => clearInterval(interval);
  }, [frames.length]);

  return (
    <div className={cls.frame_container}>
      <img src={frames[frameIndex]} alt={`Frame ${frameIndex + 1}`} className="frame" />
    </div>
  );
};

export default Flower;
