import React from 'react';
import cls from './footer.module.scss';

const footer: React.FC = () => {
    return (
        <footer className={cls.footer}>
            <div className={cls.line}>
                <img src='./assets/backgrounds/line.png' alt=''/>
            </div>
            <h2>Join our socials</h2>
            <div className={cls.footer_images}>
                <a href='https://x.com/tonquester' target='_tblank'><img src='./assets/images/0.svg' alt=''/></a>
                <a href='https://t.me/tonquester_bot' target='_tblank' ><img src='./assets/images/2.svg' alt=''/></a>
            </div>
            
        </footer>
    );
}

export default footer;
 