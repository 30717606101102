import React from 'react';
import cls from './StoneBlock.module.scss';

const StoneBlock: React.FC = () => {
    return (
        <section className={cls.background}>
            <div className={cls.container} style={{backgroundImage:'url(./assets/backgrounds/stone.png)'}}>
                <div className={cls.content}>
                    <h2>Project Roadmap</h2>
                    <ul className={cls.stone_list}>
                        <li className={cls.stone_list_item}>
                            <span>Q1 2024</span>
                            <p>Realized the problem of TON adoption Idea of how to do solve it</p>
                        </li>
                        <li className={cls.stone_list_item}>
                            <span>Q2 2024</span>
                            <ul className={cls.star_list}>
                                <li>Stealth launch</li>
                                <li>MVP launch</li>
                                <li>Full launch in TG mini-app</li>
                                <li>Increase number of quests and tasks available</li>
                            </ul>
                        </li><li className={cls.stone_list_item}>
                            <span>Q3 2024</span>
                            <ul className={cls.star_list}>
                                <li>Token presale </li>
                                <li>Quests automatization </li>
                                <li>TON passport </li>
                                <li>Web platform MVP launch</li>
                            </ul>
                        </li><li className={cls.stone_list_item}>
                            <span>Q4 2024</span>
                            <ul className={cls.star_list}>
                                <li>Web platform full launch</li>
                                <li>Launchpad platform MVP</li>
                            </ul>
                        </li>
                    </ul>
                    <div className={cls.river_castle}>
                        <img className={cls.river} src='./assets/backgrounds/w1.png' alt=''/>
                        <img className={cls.castele} src='./assets/backgrounds/castele.png' alt=''/>
                        
                    </div>
                    
                </div>
            </div>
           
        </section>
    );
}

export default StoneBlock;
