import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import SliderItem from '../swiperItem/SwiperItem';
import { Navigation } from 'swiper/modules';
import Button from '../button/Button';
import cls from './SliderBlock.module.scss';

interface Item {
  title: string;
  description: string;
  image: string;
  avatar: string;
  name: string;
  status: string;
  buttonText: string;
}

interface CustomSliderProps {
  items: Item[];
}

const PrevButton = () => (
  <div className={`${cls.custom_prev_button} custom-prev`}>
    <img src="./assets/images/arrow.svg" alt="Previous" />
  </div>
);

const NextButton = () => (
  <div className={`${cls.custom_next_button} custom-next`}>
    <img src="./assets/images/arrowsec.svg" alt="Next" />
  </div>
);

const SliderBlock: React.FC<CustomSliderProps> = ({ items }) => {
  const isMobile = window.innerWidth < 850;

  return (
    <section className={cls.section_background}>
      <div className={cls.container}>
        <div className={cls.river_castle}>
          <img src='./assets/backgrounds/w2.png' alt=''/>
        </div>
        <h2>Campaigns examples</h2>
        
        {!isMobile && (
          <div className={cls.card_container}>
            {items.map((item, index) => (
              <div key={index} className={cls.slide_item}>
                <SliderItem item={item} />
              </div>
            ))}
          </div>
        )}
        
        {isMobile && (
          <Swiper
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={1.2}
            centeredSlides={true}
            breakpoints={{
              400: { slidesPerView: 1.35 },
              500: { slidesPerView: 1.55 },
              600: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
              1400: { slidesPerView: 4 },
              1700: { slidesPerView: 5 },
            }}
            navigation={{
              prevEl: '.custom-prev',
              nextEl: '.custom-next',
            }}
            className={cls.swiper}
          >
            {items.map((item, index) => (
              <SwiperSlide key={index} className={cls.slide_item}>
                <SliderItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        
        <div className={cls.navigation}>
          {/* <PrevButton />
          <NextButton /> */}
        </div>
      </div>
      
      <div className={cls.button_container}>
        <Button content='Whitepaper & FAQ' link="https://skillful-message-04c.notion.site/02775d35826040fc875b4e4fd7b5cd9a?pvs=4"/>
      </div>
      
      <div className={cls.slider_back}>
        <picture>
          <img src='./assets/backgrounds/dirt.png' alt='' />
        </picture>
      </div>
    </section>
  );
}

export default SliderBlock;
